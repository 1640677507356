<template>
  <div class="account-company">
    <div class="section company">
      <div class="item">
        <div class="label">企业名称：</div>
        <div class="data">{{ userInfo.company_name }}<span style="color: #999;">({{ userInfo.company_type === 1 ? '认证企业' :
          '普通企业' }})</span></div>
        <!-- <div class="btn">升级认证用户企业</div> -->
        <div @click="isShowCompanyAuth = true" v-if="userInfo.company_auth.auth_status === null" class="btn">升级认证企业用户
        </div>
        <div v-else-if="userInfo.company_auth.auth_status === 0" class="btn" style="cursor: not-allowed;">认证审核中</div>
        <div @click="isShowCompanyAuth = true" v-else-if="userInfo.company_auth.auth_status === 1" class="btn">已拒审，重新认证
        </div>
      </div>
      <div class="item">
        <div class="label">发票信息：</div>
        <div class="data">
          <span>增值税专用发票</span>
          <span class="line"></span>
          <span>{{ invoicea.headers }}</span>
        </div>
        <div @click="isShowInvoiceaEdit = true" class="btn">修改</div>
      </div>
      <div class="item">
        <div class="label">收货地址：</div>
        <div class="data">{{ defaultAddress }}</div>
        <div @click="isShowAddressModal = true" class="btn">设置</div>
      </div>
    </div>
    <div class="section user">
      <div class="info-box">
        <div class="avatar">
          <img :src="userInfo.avatar" alt="">
        </div>
        <div class="info">
          <div class="nickname"><span class="label">昵称：</span>{{ userInfo.name }}</div>
          <div class="mobile"><span class="label">绑定手机：</span>{{ userInfo.mobile }}</div>
        </div>
      </div>
      <div class="actions">
        <div @click="isShowResetPassword = true" class="btn">修改登录密码</div>
        <div @click="isShowBindMobile = true" class="btn">修改绑定手机</div>
      </div>
    </div>
    <CompanyAuth @hideCompanyAuth="hideCompanyAuth" v-if="isShowCompanyAuth" />
    <InvoiceaEdit :invoiceaData="invoicea" @hideInvoiceaEdit="hideInvoiceaEdit" @updateInvoicea="getInvoicea"
      v-if="isShowInvoiceaEdit" />
    <ResetPassword @hideResetPassword="hideResetPassword" v-if="isShowResetPassword" />
    <BindMobile @hideBindMobile="hideBindMobile" v-if="isShowBindMobile" />
    <AddressModal @hideAddressModal="hideAddressModal" v-if="isShowAddressModal" />
  </div>
</template>
<script>
import CompanyAuth from '@/components/user/company-auth.vue'
import InvoiceaEdit from '@/components/user/invoicea-edit.vue'
import ResetPassword from '@/components/user/reset-password.vue'
import BindMobile from '@/components/user/bind-mobile.vue'
import AddressModal from '@/components/user/address/index.vue'
import { mapState } from 'vuex'
export default {
  components: {
    CompanyAuth,
    InvoiceaEdit,
    ResetPassword,
    BindMobile,
    AddressModal
  },
  data() {
    return {
      isShowCompanyAuth: false,
      isShowInvoiceaEdit: false,
      isShowResetPassword: false,
      isShowBindMobile: false,
      isShowAddressModal: false,
      invoicea: {},
      defaultAddress: ''
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  created() {
    this.getInvoicea()
    this.getDefaultAddress()
  },
  methods: {
    // 获取默认收货地址
    async getDefaultAddress() {
      const res = await this.$http.get('/api/address', { page: 1, per_page: 1 })
      // console.log(res)
      if (res.data && res.data[0]) {
        this.defaultAddress = res.data[0].province + res.data[0].city + res.data[0].area + res.data[0].address
      }
    },
    // 获取发票信息
    async getInvoicea() {
      const res = await this.$http.get('/api/invoice/my', {})
      // console.log(res)
      this.invoicea = res
    },
    hideCompanyAuth() {
      this.isShowCompanyAuth = false
    },
    hideInvoiceaEdit() {
      this.isShowInvoiceaEdit = false
    },
    hideResetPassword() {
      this.isShowResetPassword = false
    },
    hideBindMobile() {
      this.isShowBindMobile = false
    },
    hideAddressModal() {
      this.isShowAddressModal = false
      this.getDefaultAddress()
    }
  }
}
</script>
<style lang="scss" scoped>
.section {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 20px;

  &.company {
    line-height: 1.2;
    padding: 30px 20px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        color: #999999;
      }

      .data {
        flex: 1;
        line-height: 1.5;
        display: flex;
        align-items: center;

        span.line {
          width: 2px;
          height: 15px;
          background-color: #e6e6e6;
          margin: 0 15px;
          margin-top: 1px;
        }
      }

      .btn {
        width: 140px;
        height: 38px;
        border-radius: 3px;
        border: 1px solid #D9262C;
        color: #D9262C;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 13px;

        &:hover {
          background-color: #f1d6d6;
          color: #D9262C;
          // border: #f1d6d6;
        }
      }
    }
  }

  &.user {
    padding: 30px 20px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info-box {
      display: flex;
      align-items: center;

      .avatar {
        width: 100px;
        height: 100px;
        background-color: #ccc;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        margin-left: 20px;

        >div {
          line-height: 1.2;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            color: #999999;
          }
        }
      }
    }

    .actions {
      .btn {
        width: 140px;
        height: 38px;
        border-radius: 3px;
        border: 1px solid #D9262C;
        color: #D9262C;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: #f1d6d6;
          color: #D9262C;
        }
      }
    }
  }
}
</style>
