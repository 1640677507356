<template>
  <div class="invoicea-edit">
    <div class="form">
      <div class="title">增值税专用发票</div>
      <div class="form-item">
        <div class="label"><span>*</span>发票抬头：</div>
        <div class="data-value">
          <input v-model="params.headers" type="text" maxlength="30" placeholder="请输入发票抬头">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>发票税务登记号：</div>
        <div class="data-value">
          <input v-model="params.tax_number" type="text" maxlength="30" placeholder="请输入发票税务登记号">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>开户银行名称：</div>
        <div class="data-value">
          <input v-model="params.account_bank_name" type="text" maxlength="30" placeholder="开户银行名称">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>基本开户账号：</div>
        <div class="data-value">
          <input v-model="params.account_bank" type="text" maxlength="30" placeholder="基本开户账号">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>注册场所地址：</div>
        <div class="data-value">
          <input v-model="params.reg_place" type="text" maxlength="250" placeholder="请输入注册场所地址">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>注册固定电话：</div>
        <div class="data-value">
          <input v-model="params.reg_tel" type="text" maxlength="30" placeholder="注册固定电话">
        </div>
      </div>
      <div class="actions">
        <div @click="saveHandle" class="btn save-btn">保存发票信息</div>
        <div @click="hideHandle" class="btn candel-btn">取消</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    invoiceaData: {
      type: { Object },
      default: {}
    }
  },
  data () {
    return {
      params: {
        headers: '',
        tax_number: '',
        account_bank_name: '',
        account_bank: '',
        reg_place: '',
        reg_tel: ''
      }
    }
  },
  created () {
    if (this.invoiceaData.headers) {
      this.params.headers = this.invoiceaData.headers
    }
    if (this.invoiceaData.tax_number) {
      this.params.tax_number = this.invoiceaData.tax_number
    }
    if (this.invoiceaData.account_bank_name) {
      this.params.account_bank_name = this.invoiceaData.account_bank_name
    }
    if (this.invoiceaData.account_bank) {
      this.params.account_bank = this.invoiceaData.account_bank
    }
    if (this.invoiceaData.reg_place) {
      this.params.reg_place = this.invoiceaData.reg_place
    }
    if (this.invoiceaData.reg_tel) {
      this.params.reg_tel = this.invoiceaData.reg_tel
    }
  },
  methods: {
    // 保存
    async saveHandle () {
      if (!this.params.headers) {
        this.$message({
          type: 'error',
          message: '发票抬头不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.tax_number) {
        this.$message({
          type: 'error',
          message: '发票税务登记号不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.account_bank_name) {
        this.$message({
          type: 'error',
          message: '开户银行名称不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.account_bank) {
        this.$message({
          type: 'error',
          message: '基本开户账号不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.reg_place) {
        this.$message({
          type: 'error',
          message: '注册场所地址不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.reg_tel) {
        this.$message({
          type: 'error',
          message: '注册固定电话不能为空',
          duration: 1500
        })
        return false
      }
      await this.$http.post('/api/invoice', this.params)
      this.$emit('updateInvoicea')
      this.hideHandle()
    },
    hideHandle () {
      this.$emit('hideInvoiceaEdit')
    }
  }
}
</script>
<style lang="scss" scoped>
.invoicea-edit {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    background-color: #fff;
    border-radius: 5px;
    width: 560px;
    padding: 40px 30px;
    .title {
      text-align: center;
      color: #D9262C;
      font-size: 24px;
      margin-bottom: 40px;
    }
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .label {
        min-width: 120px;
        text-align: right;
        span {
          color: red;
        }
      }
      .data-value {
        flex: 1;
        input {
          width: 100%;
          line-height: 40px;
          outline: none;
          border-radius: 2px;
          border: 1px solid #e6e6e6;
          padding: 0 13px;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      .btn {
        width: 130px;
        height: 38px;
        border: 1px solid #D9262C;
        color: #D9262C;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        border-radius: 3px;
        cursor: pointer;
        &.save-btn {
          background-color: #D9262C;
          color: #fff;
        }
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
</style>
